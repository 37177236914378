import * as Sentry from '@sentry/astro';

Sentry.init({
	dsn: 'https://036f1afedaee26c438ee0f19da9a1d78@o4504770655092736.ingest.us.sentry.io/4508282141999104',
	integrations: [
		Sentry.feedbackIntegration({
			// Additional SDK configuration goes in here, for example:
			colorScheme: 'system',
			isEmailRequired: true,
		}),
	],
});
